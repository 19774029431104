import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar: React.FC = () => {
  const location = useLocation();

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">全境封锁2查询工具</Link>
      </div>
      <div className="navbar-links">
        <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
          首页
        </Link>
        <Link to="/leaderboard" className={location.pathname === '/leaderboard' ? 'active' : ''}>
          排行榜
        </Link>
        <Link to="/stats" className={location.pathname === '/stats' ? 'active' : ''}>
          数据统计
        </Link>
      </div>
    </nav>
  );
};

export default Navbar; 