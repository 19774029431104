import React, { useState } from 'react';
import './Home.css';

const Home: React.FC = () => {
  const [activePlatform, setActivePlatform] = useState<'ubisoft' | 'xbox' | 'playstation'>('ubisoft');

  return (
    <div className="home">
      <div className="platform-select">
        <div className="platform-icons">
          <button 
            className={`platform-icon ${activePlatform === 'ubisoft' ? 'active' : ''}`}
            onClick={() => setActivePlatform('ubisoft')}
          >
            <img src="/ubisoft.png" alt="Ubisoft" />
          </button>
          <button 
            className={`platform-icon ${activePlatform === 'xbox' ? 'active' : ''}`}
            onClick={() => setActivePlatform('xbox')}
          >
            <img src="/xbox.png" alt="Xbox" />
          </button>
          <button 
            className={`platform-icon ${activePlatform === 'playstation' ? 'active' : ''}`}
            onClick={() => setActivePlatform('playstation')}
          >
            <img src="/playstation.png" alt="PlayStation" />
          </button>
        </div>
        <div className="search-section">
          <div className="search-box">
            <input 
              type="text" 
              placeholder="输入游戏ID" 
              className="search-input"
            />
            <button className="search-button">
              搜索
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home; 